/* eslint-disable require-jsdoc */
/* eslint-disable */
// React imports
import React, {useEffect, useState} from 'react';

// DuckDB import
// import dataDB from './db/loadData.js';

// Custom Components and functions
import {getMantissaExponent, findMetaData, findOMVwithHighestRange, findNonOMVFieldWithLowestRange, createColumns} from './data.js';
import {checkedChannel} from './utils.js';
import {combToPlot} from './combinations.js'
import SideMenu from './components/SideMenu/SideMenu.js';
import DataMenu from './components/DataMenu/DataMenu.js';
import VisualVariablesTable from './components/VisualVariablesTable/VisualVariablesTable.js';
import PlotComponent from './components/PlotComponent.js';
import Modal from './components/Modal/Modal.js'
import ErrorBoundary from './ErrorBoundary.js';
// Style
import './App.scss';
import InitialRender from './components/InitialRender/InitialRender.js';
import { FaInfo } from "react-icons/fa";

let dataDB;

export default function App({db}) {
  if (db) {
    dataDB = db;
  }

  // data
  const [availableDatasets, setDatasets] = useState(null); // dataset name
  const defaultDataset = 'nominalSmall';
  const [digitsMantissa, setDigitsMantissa] = useState(1); // set default digits for mantissa equal to 1
  const [datasetName, setDatasetName] = useState(null); // dataset name
  const [dataset, setDataset] = useState(null); // dataset row, without mantissa and exponent
  const [omv, setOmv] = useState(null);
  const [selectedColumns, setColumns] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [data, setData] = useState(null); // data after transformations
  // visual manipulation
  const [mark, setMark] = useState("point"); // set point as the default mark
  const [visualVariables, setVisualVariables] = useState({
    positionX: null,
    positionY: null,
    column: null,
    row: null,
    length: null,
    area: null,
    // angle: null,
    // curvature: null,
    units: null,
    countTexture: null,
    // countShape: null,
    // countSymbol: null,
    colorIntensity: null,
    colorHue: null,
    shape: null,
    // texture: null,
    // orientation: null,
  });
  // loading, exploring and errors
  const [initiation, setInitiation] = useState(true); // for the initial loading of data and initiation to the application
  const [loadingDataset, setLoadingDataset] = useState(null); // for checking that dataset has been loaded
  const [loadingQuery, setLoadingQuery] = useState(null); // for checking that data query, based on the selected columns has been loaded
  const [uploadedDatasets, setUploadedDatasets] = useState([]);
  const [messageUpload, setMessageUpload] = useState("Upload a .parquet file");
  const [error, setError] = useState(Error());
  const [isModalOpen, setIsModalOpen] = useState(true);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // List with visualizations that are implemented with D3 and not with Plot
  const noPlotSupported = ['angle', 'curvature', 'texture', 'orientation'];

  // UPDATE FUNCTIONS
  // Event handler to update Oreder of Magnitude value state
  const updateDataset = (value) => {
    setDatasetName(value);
  };

  // Event handler to update Order of Magnitude value state
  const updateExploring = (value) => {
    setInitiation(false);
  };


  // Event handler to update Oreder of Magnitude value state
  const updateOMV = (value) => {
    setOmv(value);
  };

  // Event handler to update visual variables' state
  const updateVisualVariables = (name, value) => {
    setVisualVariables((prevVisualVariables) => ({
      ...prevVisualVariables,
      [name]: value,
    }));
  };

  // Event handler to update selected columns state
  const updateColumns = (columns) => {
    setColumns([...columns]);
  };

  // Event handler to update mark's state
  const updateMark = (value) => {
    setMark(value);
  };


  // Event handler to update digits for mantissa state
  const updateDigitsMantissa = (digits) => {
    setDigitsMantissa(digits);
  };

  const uploadFile = (file) => {
    const fileBlobURL = URL.createObjectURL(file);
    const fileName = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
    console.log('File URL:', fileBlobURL);
    console.log('File:', fileName);
    const uploadFile = {[fileName]:fileBlobURL}
    console.log('uploadFile:', uploadFile);  
    setUploadedDatasets([...uploadedDatasets, uploadFile])
    const fetchData = async () => {
      try {
        await dataDB.uploadFiletoDB(uploadedDatasets);
      } catch (e) {
        console.error('Error fetching data: ', e);
      } finally {
        setDatasets([...availableDatasets, fileName]);
        // setNewFile(fileName)
        setMessageUpload(fileName + ".parquet uploaded!")
    }
    } 
    // Call the async function
    fetchData();
  }


  // Check if chart combination is supported from plot
  const supportedByPlot = () => {
    // for the visual variables that are checked
    const activeVariables = Object.keys(visualVariables).filter((v) => checkedChannel(visualVariables[v]));
    // Check if at least one value from the active variables is included at the list with noSupportedPlot
    // return true if the configuration is supported by plot
    // return false if the configuration is not supported
    return !activeVariables.some((value) => noPlotSupported.includes(value));
  };

  const throwError = () => {
    throw Error('I\'m an error');
  };

  const checkAllAttributesNull = () => {
    return Object.values(visualVariables).every((value) => value === null);
  };

  // UseEffects
  // initial rendering
  useEffect(() => {
    combToPlot();
    if (!dataDB) {
      import('./db/loadData.js').then((db) => {
        dataDB = db.default;
        setDatasets(dataDB.getDatasetNames());
        setDatasetName(defaultDataset);
        //setMark("point")
      });
    } else {
      setDatasets(dataDB.getDatasetNames());
      setDatasetName(defaultDataset);
    }
  }, []);

  // useEffect(() => {
  //     setDatasetName(newFile);
  // }, [newFile]);

  function downloadObjectAsJson(exportObj, exportName) {
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj, (_, v) => typeof v === 'bigint' ? {__type: 'bigint', value: v.toString()} : v));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', exportName + '.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  // update raw dataset
  useEffect(() => {
    // Define the async function
    // Run only when the dataset change not the first time
    if (datasetName === null || !dataDB) return;
    const fetchData = async () => {
      // setLoadingDataset(true); // Start loading
      try {
        const resultsFromDuckDB = await dataDB.getAllFromDataset(datasetName, uploadedDatasets);
        // downloadObjectAsJson(resultsFromDuckDB, datasetName); 
        const mtData = findMetaData(resultsFromDuckDB);
        const largerOMV = findOMVwithHighestRange(mtData); // Find the OMV with the larger range and set it as default value
        console.log("update", largerOMV);
        // return columns
        setMetadata(mtData);
        if (largerOMV != undefined) {
          setOmv(largerOMV.key);
          console.log("largerOMV.key", largerOMV.key);
        }
        const columns = createColumns(mtData);
        console.log('columns', columns);
        // update columns
        setColumns(columns);
        setDataset([...resultsFromDuckDB]);
      } catch (e) {
        console.error('Error fetching data: ', e);
      } finally {
        // setLoadingDataset(false); // End loading whether success or fail
      }
    };

    // Call the async function
    fetchData();
  }, [datasetName]);

  // updates application data when needed
  useEffect(() => {
    // update data when digits change
    if (dataset === null || selectedColumns === null) return;
    try {
      console.log(digitsMantissa);
      if (checkAllAttributesNull) { // first time, before loading
        updateVisualVariables('positionX', selectedColumns[selectedColumns.length - 1]['key']);
        updateVisualVariables('positionY', 'exponent');
      }
      setData(getMantissaExponent(digitsMantissa, dataset, omv));
    } catch (e) {
      setError(e);
      console.error(e);
    }
  }, [dataset, digitsMantissa]);

    // updates application data when needed
    useEffect(() => {
      // update data when digits change
      if (dataset === null || selectedColumns === null) return;
      try {
        console.log(mark);
        
      } catch (e) {
        setError(e);
        console.error(e);
      }
    }, [mark]);

  // uncomment in case we need custom query
  // for now the user doesn't manipulate omv or selected columns
  // handles changes in omv, selectedColumns and dataset
  // sum omv and makes the group by, based on the selections
  // useEffect(() => {
  //   // Define the async function
  //   if (datasetName === null || omv === null || selectedColumns === null) return;
  //   const fetchData = async () => {
  //     setLoadingQuery(true); // Start loading
  //     try {
  //       // Query to return the data based on the selections
  //       const resultsFromDuckDB = await dataDB.getOMVperSelectedColumns(omv, selectedColumns, datasetName);
  //       // to do: maybe you have to use a memo to not run the same query
  //       setDataset([...resultsFromDuckDB]);
  //     } catch (e) {
  //       console.error('Error fetching data: ', e);
  //     } finally {
  //       setLoadingQuery(false); // End loading whether success or fail
  //       console.log('query dataset: ', dataset);
  //     }
  //   };

  //   // Call the async function
  //   fetchData();
  // }, [omv, selectedColumns, datasetName]);

  const perWhatisVisualized = () => {
    if(selectedColumns[2].key === "Category"){
      return "per video Category";
    } else if(selectedColumns[2].key === "Date"){
      return "for each week between January and May 2018";
    } else if(selectedColumns[2].key === "LikesPerComment" || selectedColumns[3].key === "LikesPerComment"){
      return "for 18 Videos based on their Likes-to-Comments ratio";
    } else {
      return "per" + formatStrings(selectedColumns.map(item => item.key));
    }
  }

  const formatStrings = (array) => {
    // Filter out 'mantissa' and 'exponent'
    const filteredArray = array.filter(item => item !== 'mantissa' && item !== 'exponent');
    
    // Check the number of elements to format the string accordingly
    if (filteredArray.length === 0) {
        return '';
    } else if (filteredArray.length === 1) {
        return ` ${filteredArray[0]}`;
    } else {
        // Join all but the last element with ', ', and append the last element with ' and '
        return ` ${filteredArray.slice(0, -1).join(', ')} and ${filteredArray[filteredArray.length - 1]}`;
    }
}

function uppercaseFirstLetter(string) {
  if (!string) return ''; // Return an empty string if the input is falsy
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function findVisualVariablesByColumn(column) {
  return Object.keys(visualVariables).filter(key => visualVariables[key] === column);
}


  return (
    <div className="main">
      <ErrorBoundary fallback={<>Oh no! Do something!</>}>
        {(data === null) ?
      <>
      Loading...
      </> :
      // <>
      //   {initiation ?
      //   <InitialRender
      //     availableDatasets = {availableDatasets}
      //     updateDataset={updateDataset}
      //     metadata={metadata}
      //     selectedColumns={selectedColumns}
      //     omv={omv}
      //     updateOMV={updateOMV}
      //     updateColumns={updateColumns}
      //     updateExploring={updateExploring}
      //     loadingDataset={loadingDataset}
      //   /> :
      <>
      <div className="menus">
      <DataMenu
          availableDatasets = {availableDatasets}
          updateDataset={updateDataset}
          metadata={metadata}
          selectedColumns={selectedColumns}
          omv={omv}
          mark={mark}
          updateOMV={updateOMV}
          updateColumns={updateColumns}
          updateExploring={updateExploring}
          updateMark={updateMark}
          loadingDataset={loadingDataset}
          updateDigitsMantissa={updateDigitsMantissa}
          digitsMantissa={digitsMantissa}
          datasetName={datasetName}
          uploadFile={uploadFile}
          messageUpload={messageUpload}
        />
        {/* <SideMenu mark={mark} updateMark={updateMark} digitsMantissa={digitsMantissa} updateDigitsMantissa={updateDigitsMantissa}/> */}
        <VisualVariablesTable
          visualVariables={visualVariables}
          updateVisualVariables={updateVisualVariables}
          mark={mark}
          digitsMantissa={digitsMantissa}
          selectedColumns={selectedColumns}
        />
      </div>
        <div className="visualization">
          <button id="info-button" onClick={openModal}><FaInfo /></button>
          {isModalOpen && <Modal closeModal={closeModal} />}
          <h3>Explore the Design Space for Visualizing Data with Large Value Ranges</h3>
          <h5 style={{color: '#7275c6', marginTop: '-15px', marginBottom: 20}}>BY DECOMPOSING THE VALUES INTO <b>MANTISSA</b> AND EXPONENT</h5>
          {(data!=null && mark!=null && selectedColumns!=null) &&
                  <div >
                    {/* <h4>Youtube Trends: Total views, seperated into exponent and mantissa, {perWhatisVisualized()}.</h4> */}
                    {/* <p>
                    {selectedColumns.map((item, index) => (
                       findVisualVariablesByColumn(item.key).length > 0 && // if there are visual variables assigned to item
                       (index < selectedColumns.length - 1 ? <span key={item.key}> <b>{uppercaseFirstLetter(item.key)}</b> is encoded with {formatStrings(findVisualVariablesByColumn(item.key))}, </span>
                      : <span key={item.key}>and <b>{item.key}</b> is encoded with {formatStrings(findVisualVariablesByColumn(item.key))}. </span>)
                    ))}
                    </p> */}
                    {supportedByPlot() ?
                    <PlotComponent
                      data={data}
                      selectedColumns={selectedColumns}
                      visualVariables={visualVariables}
                      digits={1}
                      mark={mark}
                      throwError={throwError}/> :
                    <div style={{width: '100%'}}><p>Not implemented in Plot</p></div>
                    }
                  </div>
                  
          }
          <p style={{color: 'red'}}>{error.message}</p>
        </div>
      </>
        }
        {/* </>
      } */}
      </ErrorBoundary>
    </div>

  );
}
